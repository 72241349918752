import React from "react"
import { Layout } from "../components/layout/Layout"
import { PseudoBox } from "@chakra-ui/core"
import { ContactLayout } from "../components/contact/layout/ContactLayout"
import { Link } from "gatsby"

const Page = () => {
  return (
    <Layout>
      <ContactLayout
        title="Kiitos!"
        message="Palaamme asiaan mahdollisimman pian."
      >
        <Link to="/">
          <PseudoBox bg="primary" color="white" p="1rem" borderRadius="10px">
            Palaa etusivulle
          </PseudoBox>
        </Link>
      </ContactLayout>
    </Layout>
  )
}

export default Page
